<nb-card>

    <nb-card-body [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large">
        <nb-tabset (changeTab)="tabChanged($event)">
            <nb-tab tabTitle="User Profile">
                <div class="container">
                    <form (ngSubmit)="onSubmit()">
                        <div class="form-group">
                            <label for="userName">userName*</label>
                            <input type="text" class="form-control" id="userName"
                                   required
                                   [(ngModel)]="model.userName" name="userName"
                                   #userName="ngModel"
                                   [disabled]="true"
                                   >
                            <div [hidden]="userName.valid || userName.pristine"
                                 class="alert alert-danger">
                              UserName is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="userRole">User Role*</label>
                            <input type="text" class="form-control" id="userRole"
                                   required
                                   [(ngModel)]="model.userRole" name="userRole"
                                   #userRole="ngModel"
                                   [disabled]="true"
                                   >
                            <div [hidden]="userRole.valid || userRole.pristine"
                                 class="alert alert-danger">
                              User Role is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="email">Email*</label>
                            <input type="email" class="form-control" id="email"
                                   required
                                   [(ngModel)]="model.email" name="email"
                                   #email="ngModel"
                                   >
                            <div [hidden]="email.valid || email.pristine"
                                 class="alert alert-danger">
                              Email is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="phone">Phone*</label>
                            <input type="text" class="form-control" id="phone"
                                   required
                                   [(ngModel)]="model.phone" name="phone"
                                   #phone="ngModel"
                                   >
                            <div [hidden]="phone.valid || phone.pristine"
                                 class="alert alert-danger">
                              Phone is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="userName">Company Name*</label>
                            <input type="text" class="form-control" id="companyName"
                                   required
                                   [(ngModel)]="model.companyName" name="companyName"
                                   #companyName="ngModel"
                                   >
                            <div [hidden]="companyName.valid || companyName.pristine"
                                 class="alert alert-danger">
                              Company Name is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="website">Website*</label>
                            <input type="text" class="form-control" id="website"
                                   required
                                   [(ngModel)]="model.website" name="website"
                                   #website="ngModel"
                                   >
                            <div [hidden]="website.valid || website.pristine"
                                 class="alert alert-danger">
                              Website is required
                            </div>
                        </div>

                        <div class="form-group">
                            <label for="designation">Designation*</label>
                            <input type="text" class="form-control" id="designation"
                                   required
                                   [(ngModel)]="model.designation" name="designation"
                                   #designation="ngModel"
                                   >
                            <div [hidden]="designation.valid || designation.pristine"
                                 class="alert alert-danger">
                              Designation is required
                            </div>
                        </div>
                    </form>

                    <section class="another-action">
                        <button nbButton hero status="primary" (click)="updateMyProfile()">Ok</button>&nbsp;&nbsp;
                        <button nbButton hero status="danger" (click)="closeDailog()">Cancel</button>
                    </section>
                </div>
            </nb-tab>
            <nb-tab tabTitle="Change Password">
              
                <div class="container">
                
                    <div class="form-group">
                        <label for="oldPassword">Old Password*</label>
                        <input type="password" class="form-control" id="oldPassword" required [(ngModel)]="modelForChgPassWd.oldPassword"
                            name="oldPassword" #oldPassword="ngModel">
                        <div [hidden]="oldPassword.valid || oldPassword.pristine" class="alert alert-danger">
                            Old Password is required
                        </div>
                    </div>
                
                    <div class="form-group">
                        <label for="password">New Password*</label>
                        <input type="password" class="form-control" id="password" required [(ngModel)]="modelForChgPassWd.password" name="password"
                            #password="ngModel">
                        <div [hidden]="password.valid || password.pristine" class="alert alert-danger">
                            New Password is required
                        </div>
                    </div>
                
                    <div class="form-group">
                        <label for="confirmPassword">Confirm Password*</label>
                        <input type="text" class="form-control" id="confirmPassword" required [(ngModel)]="modelForChgPassWd.confirmPassword"
                            name="confirmPassword" #confirmPassword="ngModel">
                        <div [hidden]="confirmPassword.valid || confirmPassword.pristine" class="alert alert-danger">
                            Confirm Password is required
                        </div>
                    </div>
                
                
                
                    <button type="submit" class="btn btn-success submit-button" (click)="changePassword()"
                    >Submit</button>
                
                </div>

            </nb-tab>
        </nb-tabset>
    </nb-card-body>
</nb-card>
