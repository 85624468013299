<div class="container">
    <h1>
        <img class="logo" src="assets/images/logo.png" alt="company logo" />
    </h1>
    <p class="sub-title">Hello! Log in with your UserName.</p>

    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">

        <div class="form-group">
            <label for="email">UserName*</label>
            <input type="email" class="form-control" id="email" autocomplete="off"
                   required
                   [(ngModel)]="model.email" name="email"
                   #email="ngModel">
            <div [hidden]="email.valid || email.pristine"
                 class="alert alert-danger">
              UserName is required
            </div>
        </div>

        <div class="form-group">
            <label for="password">Password*</label>
            <input type="password" class="form-control" id="password" autocomplete="off"
                   required
                   [(ngModel)]="model.password" name="password"
                   #password="ngModel">
            <div [hidden]="password.valid || password.pristine"
                 class="alert alert-danger">
              Password is required
            </div>
        </div>

        <div class="form-check">
            <input type="checkbox" class="form-check-input" id="check1" name="option1" value="rememberMe" [(ngModel)]="model.rememberMe">
            <label class="form-check-label" for="check1">Remember Me</label>
        </div><br><br>

        <button type="submit" class="btn btn-success submit-button" [disabled]="!heroForm.form.valid"
        [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large"
        >Submit</button>

    </form>

    <section class="another-action" aria-label="Register">
        Don't have an account? <a class="text-link" routerLink="../register">Register</a>
    </section>
    <section class="another-action">
        <a class="text-link" routerLink="../request-password">Forgot Password?</a>
    </section>
  
</div><br /><br />
            <div class="bottom" style="text-align:center;margin-left:24%;margin-right:15%">
              {{copyright}}
            </div>
