import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../config.service';
import {CommonServicesService} from '../common-services.service';
import {ToastrComponent} from '../modal-overlays/toastr/toastr.component'

@Component({
  selector: 'ngx-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})
export class LoginComponent implements OnInit {

  constructor(private commonService: CommonServicesService,
    private ToastrComponent:ToastrComponent,
    private configService:ConfigService,
    private Router: Router) { }

  model:any={rememberMe:false,email:'',password:''};
  loadingLargeGroup = false;
  copyright: any;

  ngOnInit(): void {
    localStorage.clear();
    let cc = window as any;
       cc.cookieconsent.initialise({
         palette: {
           popup: {
             background: "#164969"
           },
           button: {
             background: "#ffe000",
             text: "#164969"
           }
         },
         theme: "classic",
         content: {
           message: "We use cookies - Cookies help us deliver the best experience on our website.",
           dismiss: "Allow cookies",
           link:"",
         }
       });
    this.copyright = this.configService.config.copyright;
  }

  onSubmit(){
    this.callLoginService();
  }

  callLoginService(){
    let Payload={
      Username:this.model.email,
      Password:this.model.password
    }
    this.loadingLargeGroup=true;
    this.commonService.httpPostServiceForContentTypeUrlencoded(environment.apiUrl+this.configService.config.loginUrl,Payload).subscribe(
      data=>{
        if (data.signInResult.succeeded) {
          this.setLoginDataInLocalStorage(data)
          //this.navigateToDashBoard();
         // this.Router.navigate(['pages/tables/registered-apps']);
         // this.setLoginDataInLocalStorage(data).then(res=> this.navigateToDashBoard());
          this.navigateToDashBoardByRole(data?.userProfile?.role);
        }else{
          //login failed
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("warning","Login Failed","Your Login Details Are Incorrect");
        }
      },(error)=>{
        this.loadingLargeGroup=false;
        this.ToastrComponent.showToast("warning","Login Failed","Something Went Wrong");
      }
    );
  }

  setLoginDataInLocalStorage(data){
    return new Promise<void>((resolve, reject) => {
      localStorage.setItem("signInResult", JSON.stringify(data.signInResult));
      localStorage.setItem("userProfile", JSON.stringify(data.userProfile));
    resolve();
    });
  }

  navigateToDashBoard(){
    this.ToastrComponent.showToast("success","Login Succeed","Your Login Details Are Correct");
    setTimeout(()=>{
      this.loadingLargeGroup=false;
      this.Router.navigate(['pages/dashboard']);
    },500);
  }
  navigateToDashBoardByRole(role) {
    if (role) {
      if (role === "Patient") {
        this.Router.navigate(['pages/auditEvents']);
      } else {
        this.Router.navigate(['pages/tables/registered-apps']);
      }
    } else {
      this.Router.navigate(['auth/login']);
    }
  }
  
}
