import { Component } from '@angular/core';
import { NbSidebarService } from '@nebular/theme';
// import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';


@Component({
  selector: 'ngx-one-column-layout',
  styleUrls: ['./one-column.layout.scss'],
  template: `
    <nb-layout windowMode>
      <nb-layout-header fixed style="width:99%;margin-left:0%;">
        <ngx-header></ngx-header>
      </nb-layout-header>

      <nb-sidebar class="menu-sidebar" tag="menu-sidebar" responsive style="height:100vh">
       <span *ngIf="!isExpanded"><img src="" alt=""></span>

        <ng-content select="nb-menu"></ng-content>
      </nb-sidebar>

      <nb-layout-column>
        <ng-content select="router-outlet"></ng-content>
      </nb-layout-column>
    </nb-layout>
  `,
})
export class OneColumnLayoutComponent {

  // deviceInfo: DeviceInfo;
  // isvisible = false;

  // constructor(public deviceDetectorService: DeviceDetectorService) { }
  // ngOnInit(): void {
  //  this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
  // }
  isExpanded: any = true;
  constructor(public sideBarService: NbSidebarService) {
    this.sideBarService.onCompact().subscribe(val => {
      this.isExpanded = val.tag;
    })
  }
  // isMobile = this.deviceDetectorService.isMobile();


  // isDesktop = this.deviceDetectorService.isDesktop();


}


// <nb-layout - footer fixed >
//  <ngx-footer > </ngx-footer>
//  < /nb-layout-footer>
