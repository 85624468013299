import { ChangeDetectorRef, Component, OnInit, TemplateRef } from '@angular/core';
import { NbDialogRef, NbDialogService } from '@nebular/theme';
import { environment } from '../../../../../environments/environment';
import { ConfigService } from '../../../../config.service';
import { CommonServicesService } from '../../../common-services.service';
import {ToastrComponent} from '../../../modal-overlays/toastr/toastr.component';

@Component({
  selector: 'ngx-profile-and-change-pass',
  templateUrl: './profile-and-change-pass.component.html',
  styleUrls: ['./profile-and-change-pass.component.scss']
})
export class ProfileAndChangePassComponent implements OnInit {

  constructor(private dialogService: NbDialogService, 
    private commonService: CommonServicesService,
    private ToastrComponent:ToastrComponent,
    private configService:ConfigService,
    private dailog: NbDialogRef<any>,
    private changeDetection: ChangeDetectorRef,) {
      let UserData = JSON.parse(localStorage.getItem('userProfile'));
      this.userProfile=UserData;
  
  }

  model:any={userName:'',userRole:'',email:'',phone:'',companyName:'',website:'',designation:''};
  modelForChgPassWd:any={oldPassword:'',password:'',confirmPassword:''};
  loadingLargeGroup:boolean=false;
  userProfile:any;

  ngOnInit(): void {
  }

  onSubmit(){
  }

  changePassword(){
    let payload=this.modelForChgPassWd;
    payload.userName=this.userProfile?.userName;
    this.loadingLargeGroup=true;
    this.commonService.httpPostServiceForContentTypeUrlencodedForCPW(environment.apiUrl+this.configService.config.changePassword,payload).subscribe(
      data=>{
        this.loadingLargeGroup=false;
        if(data.message!='' && data.message!=null){
          this.ToastrComponent.showToast("success","success",data?.message);
        }
      },(error)=>{
        this.loadingLargeGroup=false;
        this.modelForChgPassWd = {oldPassword:'',password:'',confirmPassword:''};
        this.ToastrComponent.showToast("warning","Failed","Something Went Wrong");
      }
    );
  }

  getMyProfile(){
    this.loadingLargeGroup=true;
    this.commonService.httpCommonGetService(environment.apiUrl+this.configService.config.getMyProfile).subscribe(
      data=>{
        this.loadingLargeGroup=false;
        this.model.userName=data?.userName;
        this.model.userRole=data?.role;
        this.model.email=data?.email;
        this.model.phone=data?.cellPhone;
        this.model.companyName=data?.companyName;
        this.model.website=data?.companyWebsite;
        this.model.designation=data?.userTitle;
      },(error)=>{
        this.loadingLargeGroup=false;
        this.model={userName:'',userRole:'',email:'',phone:'',companyName:'',website:'',designation:''};
        this.ToastrComponent.showToast("warning","Failed","Something Went Wrong");
      }
    );
  }

  updateMyProfile(){
    let payload={
      email:this.model.email,
      cellPhone:this.model.phone,
      companyName:this.model.companyName,
      companyWebsite:this.model.website,
      userTitle:this.model.designation,
    };
    this.loadingLargeGroup=true;
    this.commonService.httpCommonPostService(environment.apiUrl+this.configService.config.updateMyProfile,payload).subscribe(
      data=>{
        if(data.isSuccess){
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("success","Success","Successfully Updated");
        }else{
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("warning","Failed","Something Went Wrong");
        }
      },(error)=>{
        this.loadingLargeGroup=false;
        this.ToastrComponent.showToast("warning","Failed",error.errors);
      }
    );
  }

  tabChanged(event){
    if(event.tabTitle==='User Profile'){
      this.getMyProfile();
    }else{

    }
  }

  closeDailog(){
    this.dailog.close();
  }



}
