<h1>
    <img class="logo" src="assets/images/logo.png" alt="company logo" />
</h1>
<h1 id="title" class="title">Forgot Password</h1>
<p class="sub-title">Enter your email address and we’ll send a link to reset your password</p>

<form (ngSubmit)="onSubmit()" #heroForm="ngForm">

    <div class="form-group">
        <label for="userid">Username*</label>
        <input type="email" class="form-control" id="userid"
               required
               [(ngModel)]="model.userid" name="userid"
               #userid="ngModel">
        <div [hidden]="userid.valid || userid.pristine"
             class="alert alert-danger">
          Username is required
        </div>
    </div>

    <div class="form-group">
        <label for="emailid">Email*</label>
        <input type="email" class="form-control" id="emailid"
               required
               [(ngModel)]="model.emailid" name="emailid"
               #emailid="ngModel">
        <div [hidden]="emailid.valid || emailid.pristine"
             class="alert alert-danger">
          Email is required
        </div>
    </div>

    <button type="submit" class="btn btn-success submit-button" [disabled]="!heroForm.form.valid"
    [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large"
    >Request password</button>

</form>

<section class="sign-in-or-up" aria-label="Sign in or sign up">
    <p><a class="text-link" routerLink="../login">Back to Log In</a></p>
    <p><a routerLink="../register" class="text-link">Register</a></p>
</section>
