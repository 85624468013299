import { Component, OnInit } from '@angular/core';
import { CommonServicesService } from '../common-services.service';
import {ToastrComponent} from '../modal-overlays/toastr/toastr.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { Guid } from 'guid-typescript';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'ngx-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss']
})
export class RegisterComponent implements OnInit {

  constructor(private commonService: CommonServicesService,
    private ToastrComponent:ToastrComponent,
    private configService:ConfigService,
    private Router: Router) { }
  checked = true;
  powers = ['Really Smart', 'Super Flexible',
            'Super Hot', 'Weather Changer'];

  role ="developer";
  loadingLargeGroup = false;
  model: any= {
    role: '',
    power: '',
    lastName: '',
    firstName: '',
    gender: '',
    email: '',
    Cellphone: '',
    dob: '',
    username: '',
    password: '',
    facilityId: '',
    passwordQuestion: '',
    passwordAnswer: '',
    companyName: '',
    companyWebsite: '',
    designation: '',
    PatientID: '',

  };


  patid: Guid;
  onSubmit() {
    if (this.role == "patient") {
      debugger;
      this.patid = Guid.create();
      this.model.PatientID = this.patid.toString();
    }
    this.model.facilityId = this.model.power;
    this.model.role=this.role;
    this.model.confirmpassword=this.model.password;
    this.registerNewAccount();
  }

  ngOnInit(): void {
    this.getRegisteredFacilitiesTableData();
  }

  registerNewAccount(){
    this.loadingLargeGroup=true;
    this.commonService.httpCommonPostService(environment.apiUrl+this.configService.config.registrationUrl,this.model).subscribe(
      data=>{
        if(data.isSuccess){
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("success","Success",data.message);
        }else{
          //login failed
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("warning","Failed",data?.error?.error?.error);
        }
      },(error)=>{
        this.loadingLargeGroup=false;
        this.ToastrComponent.showToast("warning","Failed",error.errors);
      }
    );
  }


  resetModel(){
    this.model={
      role:'',
      power:'',
      lastName:'',
      firstName:'',
      gender:'',
      email:'',
      Cellphone:'',
      dob:'',
      username:'',
      password: '',
      facilityId: '',
      passwordQuestion:'',
      passwordAnswer:'',
      companyName:'',
      companyWebsite:'',
      designation: '',
      PatientID: ''
    };
  }
  data = [{
    id: 1,
    FacilityName: 'GoodHealthClinic',
    Address: '8350 office Drive Park,Gr and Blanc',
    city: 'Detroit',
    firstName: 'Mark',
    lastName: 'Otto',
    email: 'mdo@gmail.com',
    phone: '280349588074',
  }]

  getRegisteredFacilitiesTableData() {
    this.loadingLargeGroup = true;
    this.commonService.httpCommonGetService(environment.apiUrl + this.configService.config.registeredFacilitiesTable).
      subscribe(
        data => {
          this.loadingLargeGroup = false;
          this.data = data;
          console.log("facilityData:", this.data);

        }, (error) => {
          this.loadingLargeGroup = false;
          this.data = [];
          this.ToastrComponent.showToast("warning", "Failed", "Something Went Wrong");
        }
      );
  }
}
