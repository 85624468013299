import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

@Component({
  selector: 'ngx-change-password',
  templateUrl: './change-password.component.html',
  styleUrls: ['./change-password.component.scss']
})
export class ChangePasswordComponent implements OnInit {

  constructor(private activateRoute: ActivatedRoute,) { }

  model:any={userName:'',oldPassword:'',password:'',confirmPassword:''};
  loadingLargeGroup = false;

  ngOnInit(): void {
    localStorage.clear();
    this.activateRoute.queryParams.subscribe(params => {
      this.model.userName=params.user;
    });
  }

  onSubmit(){
  }

}
