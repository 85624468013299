import { Component, OnInit } from '@angular/core';
import { CommonServicesService } from '../common-services.service';
import {ToastrComponent} from '../modal-overlays/toastr/toastr.component';
import { Router } from '@angular/router';
import { environment } from '../../../environments/environment';
import { ConfigService } from '../../config.service';

@Component({
  selector: 'ngx-forgot-password',
  templateUrl: './forgot-password.component.html',
  styleUrls: ['./forgot-password.component.scss']
})
export class ForgotPasswordComponent implements OnInit {

  constructor(private commonService: CommonServicesService,
    private ToastrComponent:ToastrComponent,
    private configService:ConfigService,
    private Router: Router) { }

  model:any={
    userid:'',
    emailid:''
  };
  loadingLargeGroup = false;

  ngOnInit(): void {
  }

  onSubmit(){
    this.requestResetPassword();
  }


  requestResetPassword(){
    this.loadingLargeGroup=true;
    this.commonService.httpPostServiceForContentTypeFormData(environment.apiUrl+this.configService.config.forgotPasswordUrl,this.model).subscribe(
      data=>{
        if(data.isSuccess){
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("success","Success",data.message);
        }else{
          //login failed
          this.loadingLargeGroup=false;
          this.ToastrComponent.showToast("warning","Failed",data.message);
        }
      },(error)=>{
        this.loadingLargeGroup=false;
        this.ToastrComponent.showToast("warning","Failed","Something Went Wrong");
      }
    );
  }
}
