import { Injectable } from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http'
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class CommonServicesService {

  constructor(private http:HttpClient) { }

  httpPostServiceForContentTypeUrlencoded(Url,Payload):Observable<any>{
    let body=new URLSearchParams();
    body.set("Username",Payload.Username);
    body.set("Password",Payload.Password);

    let _headers=new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded'
    });
    return this.http.post(Url,body,{headers:_headers});
  }
  httpCommonPutService(Url, Payload): Observable<any> {
    return this.http.put(Url, Payload);
  }
  httpPostServiceForContentTypeFormData(Url,Payload):Observable<any>{
    let  body = new URLSearchParams();
    body.append("userid",Payload.userid);
    body.append("emailid",Payload.emailid);

    let _headers=new HttpHeaders({
      'Content-Type':'application/x-www-form-urlencoded'
    });
    return this.http.post(Url,body,{headers:_headers});
  }

  httpPostServiceForRedirected(Url, Payload): Observable<any> {
    return this.http.post(Url, Payload, { responseType: 'text' });  }
  

  httpCommonPostService(Url,Payload):Observable<any>{
    return this.http.post(Url,Payload);
  }
  httpCommonGetService(Url): Observable<any> {
    return this.http.get(Url);
  }
  httpCommonDeleteService(Url): Observable<any> {
    return this.http.delete(Url);
  }
  httpPostServiceForContentTypeUrlencodedForCPW(Url, Payload): Observable<any> {
    let body = new URLSearchParams();
    body.set("oldpassword", Payload.oldPassword);
    body.set("newpassword", Payload.password);
    body.set("confirmpassword", Payload.confirmPassword);
    let _headers = new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded'
    });
    return this.http.post(Url, body, { headers: _headers });

  }

}
