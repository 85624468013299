<div class="container">
  
    <div>

      <h1>
        <img class="logo" src="assets/images/logo.png" alt="company logo" />
      </h1>
      <h1 id="title" class="title">{{role[0].toUpperCase() + role.slice(1)}} Registration</h1>
      <br>
      <form (ngSubmit)="onSubmit()" #heroForm="ngForm">
        <div class="form-group">

          <div class="form-check">
            <input class="form-check-input" type="radio" style="height:15px; width:15px;" name="flexRadioDefault" id="flexRadioDefault1" value="developer" checked="checked" [(ngModel)]="role" (click)="resetModel()">
            <label class="form-check-label" for="flexRadioDefault1">
              Developer
            </label>
            &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            <input class="form-check-input" type="radio" style="height:15px; width:15px;" name="flexRadioDefault" id="flexRadioDefault2" value="patient"  [(ngModel)]="role" (click)="resetModel()">
            <label class="form-check-label" for="flexRadioDefault2">
              Patient
            </label>
          </div>
        </div>

        <div *ngIf="role==='patient'" class="form-group">
          <label for="power">Select Facility*</label>
          <select class="form-control" id="power"
                  required
                  [(ngModel)]="model.power" name="power"
                  #power="ngModel">
            <option >--select facility--</option>
            <option *ngFor="let d of data" [value]="d.id">{{d.name}}</option>
          </select>
          <div [hidden]="power.valid || power.pristine" class="alert alert-danger">
            Facility is required
          </div>
        </div>

        <div class="form-group">
          <label for="lastName">Last Name*</label>
          <input nbInput fullWidth type="text" class="form-control" id="lastName"
                 required
                 [(ngModel)]="model.lastName" name="lastName"
                 #lastName="ngModel">
          <div [hidden]="lastName.valid || lastName.pristine"
               class="alert alert-danger">
            lastName is required
          </div>
        </div>

        <div class="form-group">
          <label for="firstName">First Name*</label>
          <input nbInput fullWidth type="text" class="form-control" id="firstName"
                 required
                 [(ngModel)]="model.firstName" name="firstName"
                 #firstName="ngModel">
          <div [hidden]="firstName.valid || firstName.pristine"
               class="alert alert-danger">
            firstName is required
          </div>
        </div>

        <div *ngIf="role==='patient'" class="form-group" class="example-radio-group">
          <label for="gender">Gender:</label> &nbsp;&nbsp;
          <div class="form-check">
            <input class="form-check-input" type="radio" name="genderMale" id="genderMale" value="male" [(ngModel)]="model.gender">
            <label class="form-check-label" for="genderMale">
              Male
            </label>
          </div> &nbsp;&nbsp;
          <div class="form-check">
            <input class="form-check-input" type="radio" name="genderFemale" id="genderFemale" value="female" [(ngModel)]="model.gender">
            <label class="form-check-label" for="genderFemale">
              Female
            </label>
          </div> &nbsp;&nbsp;
          <div class="form-check">
            <input class="form-check-input" type="radio" name="genderUnknown" id="genderUnknown" value="unknown" [(ngModel)]="model.gender">
            <label class="form-check-label" for="genderUnknown">
              Unknown
            </label>
          </div>
        </div>

        <div class="form-group">
          <label for="email">Email*</label>
          <input nbInput fullWidth type="email" class="form-control" id="email"
                 required
                 [(ngModel)]="model.email" name="email"
                 #email="ngModel">
          <div [hidden]="email.valid || email.pristine"
               class="alert alert-danger">
            email is required
          </div>
        </div>

        <div class="form-group">
          <label for="Cellphone">Cell Number*</label>
          <input nbInput fullWidth type="text" class="form-control" id="Cellphone"
                 required
                 [(ngModel)]="model.Cellphone" name="Cellphone"
                 #cellNumber="ngModel">
          <div [hidden]="cellNumber.valid || cellNumber.pristine"
               class="alert alert-danger">
            Cell Phone is required
          </div>
        </div>

        <div *ngIf="role==='patient'" class="form-group">
          <label for="dob">Date Of Birth*</label>
          <input nbInput fullWidth type="date" class="form-control" id="dob"
                 required
                 [(ngModel)]="model.dob" name="dob"
                 #dob="ngModel">
          <div [hidden]="dob.valid || dob.pristine"
               class="alert alert-danger">
            Date Of Birth is required
          </div>
        </div>


        <div class="form-group">
          <label for="userName">UserName*</label>
          <input nbInput  fullWidth type="email" class="form-control" id="username"
                 required
                 [(ngModel)]="model.username" name="username"
                 #username="ngModel">
          <div [hidden]="username.valid || username.pristine"
               class="alert alert-danger">
            UserName is required
          </div>
        </div>

        <div class="form-group">
          <label for="password">Password*</label>
          <input nbInput fullWidth type="password" class="form-control" id="password"
                 required
                 [(ngModel)]="model.password" name="password"
                 #password="ngModel">
          <div [hidden]="password.valid || password.pristine"
               class="alert alert-danger">
            Password is required
          </div>
        </div>

        <div *ngIf="role==='developer'" class="form-group">
          <label for="passwordQuestion">Password Question*</label>
          <input nbInput fullWidth type="text" class="form-control" id="passwordQuestion"
                 required
                 [(ngModel)]="model.passwordQuestion" name="passwordQuestion"
                 #passwordQuestion="ngModel">
          <div [hidden]="passwordQuestion.valid || passwordQuestion.pristine"
               class="alert alert-danger">
            Password Question is required
          </div>
        </div>

        <div *ngIf="role==='developer'" class="form-group">
          <label for="passwordAnswer">Password Answer*</label>
          <input nbInput  fullWidth type="text" class="form-control" id="passwordAnswer"
                 required
                 [(ngModel)]="model.passwordAnswer" name="passwordAnswer"
                 #passwordAnswer="ngModel">
          <div [hidden]="passwordAnswer.valid || passwordAnswer.pristine"
               class="alert alert-danger">
            Password Answer is required
          </div>
        </div>

        <div *ngIf="role==='developer'" class="form-group">
          <label for="companyName">Company Name*</label>
          <input nbInput  fullWidth type="text" class="form-control" id="companyName"
                 required
                 [(ngModel)]="model.companyName" name="companyName"
                 #companyName="ngModel">
          <div [hidden]="companyName.valid || companyName.pristine"
               class="alert alert-danger">
            Company Name is required
          </div>
        </div>

        <div *ngIf="role==='developer'" class="form-group">
          <label for="companyWebsite">Company Website*</label>
          <input nbInput  fullWidth type="text" class="form-control" id="companyWebsite"
                 required
                 [(ngModel)]="model.companyWebsite" name="companyWebsite"
                 #companyWebsite="ngModel">
          <div [hidden]="companyWebsite.valid || companyWebsite.pristine"
               class="alert alert-danger">
            Company Website is required
          </div>
        </div>

        <div *ngIf="role==='developer'" class="form-group">
          <label for="designation">Designation*</label>
          <input nbInput  fullWidth type="text" class="form-control" id="designation"
                 required
                 [(ngModel)]="model.designation" name="designation"
                 #designation="ngModel">
          <div [hidden]="designation.valid || designation.pristine"
               class="alert alert-danger">
            Designation is required
          </div>
        </div>


        <section class="sign-in-or-up" aria-label="Sign in or sign up">
          <button type="submit" class="btn btn-success submit-button"
                  [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large">
            Submit
          </button>&nbsp;&nbsp;
          <button type="button" class="btn btn-default Cancel-button" (click)="heroForm.reset()" routerLink="../login">Cancel</button>
        </section>

        <div>
          <hr>
        </div>

      </form>
    </div>
</div>
