import { Component, OnDestroy, OnInit } from '@angular/core';
import { NbDialogRef, NbDialogService, NbMediaBreakpointsService, NbMenuService, NbSidebarService, NbThemeService } from '@nebular/theme';

import { UserData } from '../../../@core/data/users';
import { LayoutService } from '../../../@core/utils';
import { map, takeUntil } from 'rxjs/operators';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
//import { DeviceDetectorService, DeviceInfo } from 'ngx-device-detector';
import { ProfileAndChangePassComponent } from '../../../pages/modal-overlays/window/profile-and-change-pass/profile-and-change-pass.component';


@Component({
  selector: 'ngx-header',
  styleUrls: ['./header.component.scss'],
  templateUrl: './header.component.html',
})
export class HeaderComponent implements OnInit, OnDestroy {

  // deviceInfo: DeviceInfo;
  isvisible = false;
  currentUser = null;
  isCompact: any = true;

  // isMobile = this.deviceDetectorService.isMobile();

  private destroy$: Subject<void> = new Subject<void>();
  userPictureOnly: boolean = false;
  user: any;

  themes = [
    {
      value: 'default',
      name: 'Light',
    },
    {
      value: 'dark',
      name: 'Dark',
    },
    {
      value: 'cosmic',
      name: 'Cosmic',
    },
    {
      value: 'corporate',
      name: 'Corporate',
    },
  ];

  currentTheme = 'default';

  userMenu = [{ title: 'Role' }, { title: 'Change Password' }];

  userMenu1 = [
    {
      title: 'Register Organization',
      icon: 'radio-button-off',
      link: '/pages/layout/stepper',
    },
    {
      title: 'Manage User',
      icon: 'radio-button-off',
      link: '/pages/layout/list',
    },
    {
      title: 'Update Organization',
      icon: 'radio-button-off',
      link: '/pages/layout/infinite-list',
    },
  ];

  userMenu2 = [{
    title: 'Audit Events',
    icon: 'file',
    link: '/pages/iot-dashboard',
  },
  ];

  userMenu3 = [
    {
      title: 'App Configuration',
      link: '/pages/forms/buttons',
    },
  ];
  userMenu4 = [

    { title: 'My Grants', link: '/pages/forms/inputs', }
  ];
  userMenu5 = [
    {
      title: 'Authorizations',
      link: '/pages/layout/accordion',
    },
    {
      title: 'Pending Authorizations',
      link: '/pages/layout/accordion',
    },
    {
      title: 'Registered App',
      link: '/pages/layout/accordion',
    },
    {
      title: 'CDS Vendors',
      link: '/pages/layout/accordion',
    },
    {
      title: 'API Resource',
      pathMatch: 'prefix',
      link: '/pages/layout/tabs',
    },

  ]
    userRole: any;

  constructor(private sidebarService: NbSidebarService,
    private menuService: NbMenuService,
    private themeService: NbThemeService,
    private userService: UserData,
    private layoutService: LayoutService,
    private router: Router,
    private dialog: NbDialogService,
    private breakpointService: NbMediaBreakpointsService)
  {
    //  if (this.deviceDetectorService.isDesktop() || this.deviceDetectorService.isTablet())
    // {
    //  this.isDesktop = this.deviceDetectorService.isDesktop();
    // }
    let userData: any = JSON.parse(localStorage.getItem('userProfile'));
    this.userRole = userData?.role;
    this.currentUser = userData?.userName;
  }

  ngOnInit() {
   // this.deviceInfo = this.deviceDetectorService.getDeviceInfo();
    this.currentTheme = this.themeService.currentTheme;

    this.userService.getUsers()
      .pipe(takeUntil(this.destroy$))
      .subscribe((users: any) => this.user = users.nick);

    const { xl } = this.breakpointService.getBreakpointsMap();
    this.themeService.onMediaQueryChange()
      .pipe(
        map(([, currentBreakpoint]) => currentBreakpoint.width < xl),
        takeUntil(this.destroy$),
      )
      .subscribe((isLessThanXl: boolean) => this.userPictureOnly = isLessThanXl);

    this.themeService.onThemeChange()
      .pipe(
        map(({ name }) => name),
        takeUntil(this.destroy$),
      )
      .subscribe(themeName => this.currentTheme = themeName);
    this.menuService.onItemClick().subscribe((item: any) => {

      if (item?.item?.title === "Change Password") {

        this.changePasswordScreen();

      }

    });
  }

  ngOnDestroy() {
    this.destroy$.next();
    this.destroy$.complete();
  }

  changeTheme(themeName: string) {
    this.themeService.changeTheme(themeName);
  }

  toggleSidebar(): boolean {
    this.sidebarService.toggle(true, 'menu-sidebar');
    this.layoutService.changeLayoutSize();
    this.isCompact = !this.isCompact;
    this.sidebarService.compact(this.isCompact)
    return false;
  }

  navigateHome() {
    this.menuService.navigateHome();
    return false;
  }
  logout() {

    this.router.navigate(['auth/login']);

  }
  changePasswordScreen() {

    this.router.navigate(['auth/change-password'], { queryParams: { user: this.currentUser } });

  }
  openDailogForProfile() {
    const dialogRef = this.dialog.open(ProfileAndChangePassComponent);
  }

}
