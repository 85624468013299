<div class="container">
    <h1>
        <img class="logo" src="assets/images/logo.png" alt="company logo" />
    </h1>
    <p class="sub-title">Hello! Please Enter Below Details.</p>

    <form (ngSubmit)="onSubmit()" #heroForm="ngForm">

        <div class="form-group">
            <label for="userName">userName*</label>
            <input type="text" class="form-control" id="userName"
                   required
                   [(ngModel)]="model.userName" name="userName"
                   #userName="ngModel"
                   [disabled]="true">
            <div [hidden]="userName.valid || userName.pristine"
                 class="alert alert-danger">
              UserName is required
            </div>
        </div>

        <div class="form-group">
            <label for="oldPassword">Old Password*</label>
            <input type="password" class="form-control" id="oldPassword"
                   required
                   [(ngModel)]="model.oldPassword" name="oldPassword"
                   #oldPassword="ngModel">
            <div [hidden]="oldPassword.valid || oldPassword.pristine"
                 class="alert alert-danger">
              Old Password is required
            </div>
        </div>

        <div class="form-group">
            <label for="password">New Password*</label>
            <input type="password" class="form-control" id="password"
                   required
                   [(ngModel)]="model.password" name="password"
                   #password="ngModel">
            <div [hidden]="password.valid || password.pristine"
                 class="alert alert-danger">
              New Password is required
            </div>
        </div>

        <div class="form-group">
            <label for="confirmPassword">Confirm Password*</label>
            <input type="text" class="form-control" id="confirmPassword"
                   required
                   [(ngModel)]="model.confirmPassword" name="confirmPassword"
                   #confirmPassword="ngModel">
            <div [hidden]="confirmPassword.valid || confirmPassword.pristine"
                 class="alert alert-danger">
              Confirm Password is required
            </div>
        </div>

        

        <button type="submit" class="btn btn-success submit-button" [disabled]="!heroForm.form.valid"
        [nbSpinner]="loadingLargeGroup" nbSpinnerStatus="success" nbSpinnerSize="large"
        >Submit</button>

    </form>

    <section class="another-action" aria-label="Register">
        LogIn ? <a class="text-link" routerLink="">Login Page</a>
    </section>
</div>