<div class="header-container">
  <div class="logo-container">
    <a (click)="toggleSidebar()" href="#" class="sidebar-toggle" nbTooltip="Collapse/Expand" nbTooltipPlacement="bottom" nbTooltipStatus="control">
      <nb-icon icon="menu-2-outline"></nb-icon>
    </a>
    <a class="logo" href="#" (click)="navigateHome()">
      <span>
        <img src="assets/images/logo.png" alt="" style="width: 200px;height: 100px;padding: 6px 0px 0px 6px;max-height: 50px;margin-left:.0rem;">
      </span>
    </a>
  </div>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
  <nb-select [selected]="currentTheme" (selectedChange)="changeTheme($event)" status="primary">
    <nb-option *ngFor="let theme of themes" [value]="theme.value"> {{ theme.name }}</nb-option>
  </nb-select>
</div>
<!--<div class="header-container">
  <button nbButton style="border:none;" [nbContextMenu]="userMenu1" ghost>Administration</button>&nbsp;&nbsp;
  <button nbButton style="border:none;" [nbContextMenu]="userMenu5" ghost>API Licensing</button>&nbsp;&nbsp;
  <button nbButton style="border:none;" [nbContextMenu]="userMenu2" ghost>Audit Events</button>&nbsp;&nbsp;
  <button nbButton style="border:none;" [nbContextMenu]="userMenu3" ghost>App Configuraton</button>&nbsp;&nbsp;
  <button nbButton style="border:none;" [nbContextMenu]="userMenu4" ghost>My Grants</button>&nbsp;

</div>-->

<div class="header-container">
  <nb-actions size="small">

    <!--<nb-action class="control-item">
      <nb-search type="rotate-layout"></nb-search>
    </nb-action>-->
    <nb-action class="user-action" icon="person" [nbPopover]="userRole" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" (click)="openDailogForProfile()"></nb-action>
    <nb-action class="user-action" icon="log-out" nbPopover="log-out" nbPopoverTrigger="hint" nbPopoverPlacement="bottom" (click)="logout()"></nb-action>
    <!--<nb-action class="user-action" *nbIsGranted="['view', 'user']" >
      <nb-user [nbContextMenu]="userMenu"
               [onlyPicture]="userPictureOnly"
               [name]="user?.name"
               [picture]="user?.picture">
      </nb-user>
    </nb-action>-->
  </nb-actions>
</div>


<!--<nb-user initial inverse size="medium" name="Administration"
           [nbContextMenu]="userMenu1"></nb-user>

  <nb-user inverse size="medium" name="Audit Events"
           [nbContextMenu]="userMenu2"></nb-user>

  <nb-user inverse size="medium" name="App Configuration"
           [nbContextMenu]="userMenu3"></nb-user>

  <nb-user inverse size="medium" name="MY Grants"
           [nbContextMenu]="userMenu4"></nb-user>-->
